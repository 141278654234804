<template>
  <div>
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/h5ScendCloud-banner1-2.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex  max-width-1200">
          <v-card color="transparent banner-text ml-6" flat>
            <v-card-title class="white--text pa-0 font-size-h5banner-title">
              万景云
            </v-card-title>
            <v-card-title class="white--text pa-0 mt-2 font-size-h5banner-tip">
              定义科技文旅新风向<br /> 拓展文旅新商机
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 万景云产品特征 -->
    <v-card class="px-4 py-6" flat>
      <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">万景云产品特征</v-card-title>
      <div class="mt-4">
        <v-row v-for="(row,rowIndex) in typeList" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card" min-height="90" width="100%" flat>
            <v-img :src="row.img" max-width="65" contain position="center"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-1 font-size-16 font-weight-bold line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.p}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>
    <!-- 万景云产品方案 -->
    <v-card flat class="py-6 position-r" color="#2181EA"> 
      <v-card-title class="justify-center font-weight-bold font-size-20 color-#333333 pa-0 line-height-28 color-FFF">万景云产品方案</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" class="xs-tab" color="white" dark>
        <v-tab v-for="(v, k) in list" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === list.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="font-size-16 letter-spacing color-FFF">{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);">
        <v-tab-item v-for="(v, k) in content" :key="k" :transition="false">
          <v-card  color="white" class="pa-5" >
            <v-img :src="v.img" width="100%" min-height="200" contain></v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold" >{{v.h2}}</v-card-title>
            <v-card-text class="pa-0 mt-2 font-size-13 color-666" v-text="v.p" v-html="v.p"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- 万景云标品介绍-AR寻宝打卡 -->
    <v-card class="warp warp3" :flat="true">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-#333333 pa-0 line-height-28 mt-6 mb-5">万景云标品介绍</v-card-title>
      <div class="box">
        <div class="right">
          <v-img src="https://h5.ophyer.cn/official_website/other/scene16.png" contain />
        </div>
        <div class="left">
          <v-card-title class="title">AR寻宝打卡</v-card-title>
          <v-card-text class="tip">• 针对景区、活动展览开发制作的线上线下一体沉浸式游览交互互动的小程序游戏</v-card-text>
          <v-card-text class="tip">• 针对不同旅游人群特点为景区设计旅游路线规划</v-card-text>
          <v-card-text class="tip">• 利用互动打卡游戏设置打卡点，为景区指定商家引流，促进商家消费以及互动抽奖提升景区二次传播</v-card-text>
          <v-card-text class="tip">• 利用AR技 术针对景区为修复文物进行场景复原，传播景区历史文化</v-card-text>
        </div>
      </div>
    </v-card>
   
    <!-- 客户案例 -->
    <!-- <v-container>
      <div class="d-flex justify-center align-center mb-9 case_card" style="width:100%;height:100%">
        <div style="width:100%">
          <div class="public-title">
            <p>CUSTOMER CASE</p>
            <div>
              <span >客户案例</span>
            </div>
          </div>
          <v-container style="max-width:1200px;">
            <v-row>
                <v-col v-for="(card, index) in anli" :key="index" cols="3">
                    <v-card
                      class="mx-auto item-card"
                      max-width="240"
                      max-height="410"
                      color="transparent"
                      @click="goCaseDetail(card)"
                    >
                      <v-img
                        max-height="340"
                        :src="card.coverImg"
                        :aspect-ratio="9/16"
                      >
                      <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                  indeterminate
                                  size="80"
                                  color="red lighten-5"
                                ></v-progress-circular>
                              </v-row>
                      </template>
                      </v-img>
                      <v-card-text class="font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>
                    </v-card>
                </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-container> -->
  </div>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
import qs from 'qs'
export default {
  name: "SceneCloud",
  
  data() {
    return {
      model: 0,
      tab: null,
      imgLoad: false,
      bannerLoad: false,
      windowHight: window.innerHeight / 2,
      anli:[textData.anli.meilizhongguo,textData.anli.hongcunjiyi,textData.anli.qinghuaxiaoyuan,textData.anli.bowuguan],
      list: [
        { title: "AR智慧文旅", h2: "结合AR超能力引领科技旅游创新发展",img: 'https://h5.ophyer.cn/official_website/other/scene11.png' },
        { title: "VR虚拟展厅", h2: "结合VR超能力引领科技旅游创新发展",img: 'https://h5.ophyer.cn/official_website/other/scene12.png'},
        { title: "智慧展馆搭建", h2: "智慧展馆搭建引领科技旅游创新发展" },
        { title: "智慧景区", h2: "智慧景区引领科技旅游创新发展" },
        { title: "VR景区体验", h2: "VR景区体验引领科技旅游创新发展" },
      ],
      typeList:[                                                                             
        // {title:'互动运营',img:require('../../../public/img/icons/SceneCloud/SceneCloudH51.png'),p:'通过百款AR游戏及剧本杀等产品标品帮助用户以娱乐互动的方式开展运营活动'},
        {title:'活动创意',img:require('../../../public/img/icons/SceneCloud/SceneCloudH51.png'),p:'通过百款AR游戏及剧本杀等产品标品帮助用户以娱乐互动的方式开展创意活动'},
        {title:'定制化服务',img:require('../../../public/img/icons/SceneCloud/SceneCloudH52.png'),p:'根据用户个性化的需求进行定制修改，为用户提供最为匹配的方案'},
        {title:'智慧化运营管理',img:require('../../../public/img/icons/SceneCloud/SceneCloudH53.png'),p:'数据采集，地图编辑，AR内容制作等完整工具链，提供高易用性和智能化管理服务'},
        {title:'大数据分析',img:require('../../../public/img/icons/SceneCloud/SceneCloudH54.png'),p:'采集游客的不同维度数据，分析并向用户输出结论，助力运营策略的迭代'},
        {title:'跨平台多终端',img:require('../../../public/img/icons/SceneCloud/SceneCloudH55.png'),p:'支持安卓，IOS，H5小程序等多渠道同时发布，确保触达更多用户场景'},
      ],
      typeListTow:[
        
      ],
      content: [
        {
          h2: "结合AR超能力引领科技旅游创新发展",
          img: 'https://h5.ophyer.cn/official_website/other/scene11.png',
          p: "• 依托AR功能，实现虚拟导航、旅游路线推荐、历史复原、AI讲解等多种功能<br/>• 提供AR定制服务，实现游戏化、互动的沉浸体验，打造特有景区形象，增强市场影响力",
        },
        {
          h2: "沉浸式互动体验打造爆款级科技虚拟展厅",
          img: 'https://h5.ophyer.cn/official_website/other/scene12.png',
          p: "• 打造全息投影场景、VR投影场景、人机互动场景<br/>• 真实还原展厅，配合VR设备实现线上参观，智能导览等多种功能<br/>• 及时传递客户利益点，打造爆款级科技虚拟展厅",
        },
        {
          h2: "搭建线下科技展馆助推城市文化特色",
          img: 'https://h5.ophyer.cn/official_website/other/scene13.png',
          // p: "• 结合地方文化特色，定制极具地域特色的科技文化体验展馆<br/>• 实现文化与科技一体化展示，用科技助推城市文化，打造现象级运营案例",
          p: "• 结合地方文化特色，定制极具地域特色的科技文化体验展馆<br/>• 实现文化与科技一体化展示，用科技助推城市文化，打造现象级精品案例",
        },
        {
          h2: "助推城市旅游服务能力",
          img: 'https://h5.ophyer.cn/official_website/other/scene14.png',
          p: "• 通过AR/VR技术帮助城市实现智慧合作，助推地方政府、企业进行智慧化产业升级转型<br/>• 依托大数据处理能力及跨城市智慧旅游服务能力，实现“一部手机游景区",
        },
        {
          h2: "为景区建立完整的科技+文化场景",
          img: 'https://h5.ophyer.cn/official_website/other/scene15.png',
          p: "• 通过移动端设备即可一次性目睹不同景点的3D景象<br/>• 模拟真实游览，在地区间穿梭，多角度、近距离观赏各种景点的景象<br/>• 通过互动手段了展示景点历史、建筑与遗迹文化",
        },
      ],
  
    };
  },
  mounted() {
    this.bannerLoad = true;
  },
  methods: {
    handleLoad() {
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
    handleTabBtn(type){
      const maxTab = this.list.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  },
};
</script>

<style scoped lang='scss'>
::v-deep .banner {
  position: relative;
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
  .v-card__subtitle, .v-card__text, .v-card__title {
    // padding: 8px 26px;
    // line-height: 21px !important;
  }
}
::v-deep .v-card__subtitle, .v-card__text ,.text-subtitle-1{
  line-height: 26px !important;
}
.banner_title {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  // padding-top: 13%;
}
.banner_span {
  font-family: Source Han Sans CN;
  color: #2672FF;
  font-size: 20px;
}
.public-title{
  height: 50px;
  margin: 53px auto 50px;
}
.warp {
  margin: 0 auto;
  text-align: center;
}
.mt-11{
  
  margin-top: 29px !important;
}
.scence_title,.scence_text{
  font-size: 18px;
  color: #FFFFFF !important;
  line-height: 26px !important;
}

::v-deep .warp3 {
  .box {
    min-width: 335px;
    // width: 1024px;
    // margin: 60px auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
  .v-card__title{
    padding: 24px;
  }
  .right{
     margin: 0 20px;
    .v-image__image{
      border-radius: 10px;
    }
  }
  .left {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    margin-right: 20px;
    margin-bottom: 24px;
    .v-card__text {
      padding: 0;
    }
    .title {
      //font-family: Source Han Sans CN;
      font-size: 18px !important;
      font-weight: 600 !important;
      padding: 15px 20px 5px !important;
    }
    .tip {
      margin: 0px 0;
      padding-left: 20px;
      
      font-size: 13px !important;
      color: rgb(102, 102, 102) !important;
      &:last-child{
        margin:  0;
      }
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
.case_card{
  .public-title{
     height: 50px;
     margin: 25px auto 50px;
   }
}
.first {
  .box {
    margin: 0px auto;
    text-align: center;
    justify-content: center;
    //border-bottom: 1px solid;
    h3 {
      margin-bottom: 40px;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 44px;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666;
      line-height: 20px;
      margin-top: 20px;
      
    }
    h2 {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
    }
    .theme--light.v-image {
      width: 100px;
      margin: 0 auto;
    }
    .img_title {
      width: 266px;
      margin: 0 auto;
      h2 {
        font-size: 20px;
        color: #333;
        // margin-top: 10px;
      }
      p {
        font-size: 14px;
        margin-top: 10px;
        // padding: 0px 45px
      }
    }
  }
}
::v-deep .v-responsive {
  overflow: inherit !important;
}

.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
    display: none;
  }
}

</style>